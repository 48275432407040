.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* width: 100% - 1rem; */
  width: calc(100vw - 2rem);
  height: 60px;
}

.dividing-line {
  height: 0.33px;
  width: 100%;
  /* width: 95vw; */
  align-self: center;
  background-color: var(--kurr-black45);
}

.footer-symbols {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  padding-top: 10px;
}

.footer-symbols img {
  height: 20.28px;
}

h3 {
  font-family: var(--font-family);
  font-size: 14px;
}

/* Hide the footer */
.footer-container--hidden {
  display: none;
}


@media screen and (max-width: 1150px) {
  .footer-container {
    /*margin-top: 40px;*/
  }
}

@media screen and (max-width: 530px) {
  h3 {
    font-size: 12px;
  }

  .footer-symbols img {
    height: 14px;
  }
}
