.list-item {
  /*
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
  */
}

.list-item a {
  display: flex;
  align-items: center;
  /*
  width: 100%;
  */
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: yellow;
}

.list-item a:hover,
.list-item a:active {
  background: #ffd900;
}

.list-item__content {
  /* Added by Niklas */
  max-width: 44rem;
  min-width: 20rem;

  /*
  margin: auto;
  padding: 0;
  */
}

.list-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.list-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: var(--kurr-black);
}

.list-item:hover h2,
.list-item:active h2,
.list-item:hover h3,
.list-item:active h3 {
  color: #292929;
}

.list-item__info h3 {
  margin: 0;
}

.username {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  color: var(--kurr-black45);
}

.new-recipe {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  color: darkgreen;
  padding-left: 8px;
}

.btn-publish-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn-publish {
  font-family: var(--font-family);
  color: var(--kurr-black85);
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.btn-publish[disabled] {
  cursor: default;
}

.published-feedback {
  color: var(--ios-green);
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
}

.btn-share-link {
  display: flex;
  align-items: center;
  cursor: "pointer";
}

.actions {
  margin-top: 4px;
  color: var(--kurr-black25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
}

.actions-divider {
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
