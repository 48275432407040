/* Helvetica Neue */
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@700&display=swap");

body {
  margin: 0;
  padding: 0;
  height: -webkit-fill-available;
}

.html {
  height: -webkit-fill-available;
}

div {
  margin: 0;
  padding: 0;
}

.full-screen-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;
}

main {
  flex-grow: 1;
}

:root {
  --font-family: "Helvetica Neue", "Arimo", "Arial", sans-serif;

  /* Colors */
  --macaroni-yellow: #ffe193;
  --piglet-peach: #fe9c7d;

  --piglet-peach2: #feb79b;

  --kurr-white: #ffffff;
  --kurr-white55: rgba(255, 255, 255, 0.55);
  --kurr-white35: rgba(255, 255, 255, 0.35);
  --kurr-white18: rgba(255, 255, 255, 0.18);
  --kurr-offwhite: #f6f6f5;

  --kurr-black: #000000;
  --kurr-black85: rgba(0, 0, 0, 0.85);
  --kurr-black45: rgba(0, 0, 0, 0.45);
  --kurr-black25: rgba(0, 0, 0, 0.25);
  --kurr-offblack: #272727;

  --ios-blue: #007aff;
  --ios-red: #ff3b30;
  --ios-green: #34c759;
  --ios-yellow: #ffcc00;
  --ios-red-system-dark: #ff453a;
  --ios-yellow-system-dark: #ffd60a;

  /* Font size */
  --font-size-l: 4.5vw;
  --font-size-m: 3vw;
  --font-size-s: 8vw;
  --font-size-xs: 2vw;

  --padding-sides: 1rem;

  /* Headline text */
  --font-style-headline: normal;
  --font-weight-headline: 400;
  --font-size-headline: 17px;
  --line-height-headline: 25px;

  /* Caption 1 */
  --font-style-caption1: normal;
  --font-weight-caption1: 400;
  --font-size-caption1: 12px;
  --line-height-caption1: 18px;
  --color-caption1: rgba(0, 0, 0, 0.45); /* Black/45 */

  /* Device (based on figma props 221229)*/
  --macbook16-width: 1728px;
  --macbook14-width: 1512px;
  --macbookAir-width: 1280px;
  --ipadMini-width: 744px;
  --iphone14ProMax-width: 430px;
  --iphoneSE-width: 320px;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
