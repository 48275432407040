.ingredient-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  margin: 8px 0 0 0;
  padding: 0;
}

.ingredient {
  padding: 0;
  margin: 0;
  height: 42px;
}

.ingredient label {
  margin-bottom: 0px;
}

.ingredient select,
.ingredient input {
  height: 42px;
  font-size: 13px;
  line-height: 19px;
}

.ingredient-delete-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ingredient__name {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  min-width: 80px;
  margin: 0;
}

.ingredient__name input {
  position: absolute;
  width: 100%;
  height: 42px;
  top: 0px;
}

.ingredient__name ul {
  position: absolute;
  top: 46px;
  z-index: 1;
  background-color: var(--kurr-white);

  list-style: none;
  padding: 0;
  font-size: 10px;
  width: 100%;
  height: fit-content;
  margin-top: 4px;

  box-shadow: 4px 6px 18px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  font-weight: 400;
  font-size: 13px;
  line-height: 19px;

  /*Make div scrollable*/
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
}

.ingredient__name ul li {
  padding: 4px;
  padding-left: 8px;
  color: var(--kurr-black45);
  cursor: pointer;
}

.ingredient__name ul li:hover {
  color: var(--kurr-black85);
  background-color: rgba(0, 0, 0, 0.02);
}

.ingredient__name ul li:first-child {
  padding-top: 8px;
}

.ingredient__name ul li:last-child {
  padding-bottom: 8px;
}

.ingredient__quantity {
  flex: 1;
  min-width: 2rem;
  max-width: 3rem;
  text-align: center;
}

.ingredient__quantity input {
  /*TODO: padding: 0 centrerar i utvecklarverktygen, men padding: 16 när man quittar utvecklarverktygen*/
  display: inline;
  text-align: center;
  padding-left: 0;
}

/*Following hides the arrows from the integer input*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ingredient__unit {
  flex: 1;
  max-width: 6.5rem;
}

.ingredient__unit select {
  padding-left: 8px;
}

.delete-ingredient-button {
  color: var(--kurr-black25);
  cursor: pointer;
}

.add-ingredient-button {
  color: var(--piglet-peach2);
}

.add-ingredient-button span {
  font-weight: 600;
}

.warning-icon {
  color: var(--ios-red);
  position: relative;
  top: 12px;
}

@media screen and (max-width: 640px) {
  .ingredient {
    padding: 0;
    margin: 0;
    height: 30px;
  }

  .ingredient select,
  .ingredient input {
    height: 30px;
    font-size: 11px;
    line-height: 16px;
  }

  .ingredient__quantity input {
    padding: 0;
  }

  .ingredient__unit {
    min-width: 5.25rem;
    max-width: 5.25rem;
  }

  .ingredient label {
    margin-bottom: 0px;
  }

  .form-control__subheading--right {
    font-size: 8px;
    line-height: 12px;
  }

  .ingredient__name ul {
    top: 34px;
    font-size: 11px;
    line-height: 16px;
  }

  .warning-icon {
    top: 6px;
  }
}
