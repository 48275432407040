.terms-and-conditions-container {
  font-family: var(--font-family);
  color: var(--kurr-black85);
}

.terms-and-conditions-container h1 {
  margin-bottom: 2rem;
}

.terms-and-conditions-container h2 {
  margin: 1rem 0;
}

.terms-and-conditions-container ul {
  list-style-type: none; /* Removes list item bullets */
  padding-left: 1rem;
}

.terms-and-conditions-container ul li {
  line-height: 1.4; /* Sets the line-height between each condition */
  margin-bottom: 1rem; /* Adds a little vertical space between each condition */
}
