.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.nav-links button {
  cursor: pointer;
  border: none;
  text-decoration: none;
  background: transparent;
  padding: 8px 0;
  
  font-family: var(--font-family);
  color: black;
  font-size: 16px;
}

.nav-links a {
  cursor: pointer;
  border: none;
  text-decoration: none;
  background: transparent;
  padding: 8px 0px 8px 16px;
  font-family: var(--font-family);
}

.nav-links__black a {
  color: black;
}

.nav-links__white a {
  color: white;
}

.nav-links__exit img {
  height: 22px;
  width: 22px;
  animation: out 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.nav-links__exit img:hover {
  animation: in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
   * ----------------------------------------
   * animation rotate 90deg
   * ----------------------------------------
   */
   @keyframes in {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
  @keyframes out {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0deg);
    }
  }