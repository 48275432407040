.form-control {
  margin: 16px 0;
  font-family: var(--font-family);
}

.form-control label,
.form-control input,
.form-control select {
  display: block;
  box-sizing: border-box;
}

.form-control label {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;


  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.25);
}

.form-control select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat right 2px center;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

.form-control input,
.form-control select {  
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);

  padding-left: 8px;
  /* box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.05); */
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.45);
}

.form-control input:disabled {
  color: transparent;
}

.form-control input:focus,
.form-control select:focus {
  outline: none;
  /*background: #ffffff; */
  border: 1px solid rgba(0, 0, 0, 0.45);
  /*border-radius: 4px; */
  box-shadow: 4px 6px 18px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.85);
}

.form-control input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}


.form-control--invalid p {
  color: red;
}

/*
.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
*/

.form-control__subheading--right {
  text-align: right;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.25);
}

.form-control__subheading--right p {
  margin-top: 4px;
}

.form-control-medium-bold {
  font-weight: 500;

}

.font {
  font-family: var(--font-family);
}

@media screen and (max-width: 640px) {

  .form-control {
    margin: 8px 0;
  }
  

  .form-control input,
  .form-control select {
    height: 42px;
    padding-left: 8px;
    font-size: 13px;
    line-height: 19px;
  }
  .form-control label {
    font-size: 12px;
    line-height: 18px;

    margin-bottom: 5px;
  }

}
