.Menu-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
}

.Menu-container__exit {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
.Menu-container__exit img {
  height: 22px;
  width: 22px;
  animation: out 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Menu-container__exit img:hover {
  animation: in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.Menu-container__text h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-l);
  color: white;
}

.Menu-container__text h4 {
  font-family: var(--font-family);
  font-size: var(--font-size-xs);
  color: white;
}

/**
   * ----------------------------------------
   * animation rotate 90deg
   * ----------------------------------------
   */
@keyframes in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
@keyframes out {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}
