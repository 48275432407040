.step-instruction {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.step-by-step-instruction-active {
  position: relative;
  margin: 0px;
  padding: 1px 16px 0 16px;
  margin-bottom: 16px;

  background: var(--kurrWhite);
  box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.step-by-step-instruction-inactive {
  display: flex;
  justify-content: space-between;
  border-radius: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding-bottom: 12px;
  padding-top: 1px;
  margin-bottom: 16px;
  /*border-bottom: 1px solid rgba(0,0,0,0.12); */
}

.step-by-step-instruction-inactive input,
.step-by-step-instruction-inactive .button-ingredient-done,
.step-by-step-instruction-inactive .input-subheading p,
.step-by-step-instruction-inactive .delete-instruction {
  display: none;
}

.input-text-and-icon-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.inactive-text-and-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin: 0 16px;
}

inactive-text-and-icon-container p {
  margin: 0;
}
.step-by-step-inactive_text {
  margin-right: 16px;
}
.step-by-step_inactive_more-icon {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
}

.button-add-ingredient {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;

  cursor: pointer;

  background-color: var(--kurr-white);
  color: var(--kurr-black85);
  border: none;

  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-decoration: underline;
}

.button-add-ingredient:disabled {
  color: rgba(0, 0, 0, 0.1);
  cursor: default;
}

.button-ingredient-done {
  padding: 0;
  padding-right: 4px;
  background-color: var(--kurr-white);
  border: none;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: var(--piglet-peach2);

  margin-top: 0px;
  cursor: pointer;
}

.button-ingredient-done:disabled {
  color: rgba(254, 183, 155, 0.3);
  cursor: default;
}

.button-ingredient-done .button-ingredient-done-section {
  margin-top: 0;
}

.ingredient-buttons {
  display: flex;
  justify-content: space-between;
  align-items: bottom;

  width: 100%;
  margin-top: 16px;
  padding-bottom: 16px;
}

/*
.ingredient-buttons button:disabled {
  color: rgba(0, 0, 0, 0.07)
}
*/

.delete-instruction {
  position: absolute;
  display: block;
  right: 0;
  color: var(--kurr-black45);
  padding: 24px 16px 4px 16px;
  cursor: pointer;
  font-size: 11px;
  text-decoration: underline;
  margin: 0;
}

/*
.delete-instruction p {
  margin: 0;
  float: right;
  font-family: var(--font-family);
  color: var(--kurr-black45);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
*/

.description-input-active {
  /*padding-right: 28px;*/
  margin-left: 0px;
}

.description-input-inactive {
  padding-right: 28px;
  margin-left: 16px;
}

.description-input-inactive-section label {
  /* color: var(--kurr-black85); */
  font-weight: 500;
  color: rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 640px) {
  .delete-instruction {
    top: 0px;
    font-size: 9px;
    padding: 16px 16px 4px 16px;
  }
}
