.typewriter-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  white-space: nowrap;
}

.highlight {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffe193 50%);
  font-family: var(--font-family);
  font-size: var(--font-size-l);
}

h1 span {
  font-family: var(--font-family);
  font-size: var(--font-size-l);
}

.styles-module_blinkingCursor__288eE {
  font-size: var(--font-size-l);
}

/* Screen sizes */
@media screen and (max-width: 1400px) {
  .highlight {
    font-size: var(--font-size-m);
  }

  h1 span {
    font-size: var(--font-size-m);
  }

  .styles-module_blinkingCursor__288eE {
    font-size: var(--font-size-m);
  }
}

@media screen and (max-width: 1150px) {
  .typewriter-container {
    align-self: center;
    padding-top: unset;
    justify-content: center;
  }
  .highlight {
    font-size: var(--font-size-s);
  }

  h1 span {
    font-size: var(--font-size-s);
  }

  .styles-module_blinkingCursor__288eE {
    font-size: var(--font-size-s);
  }
}
