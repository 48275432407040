.instructions {
  max-width: 44rem;
  min-width: 20rem;
}

.add-buttons-container {
  display: flex;
  margin: 8px 8px;
}

.add-buttons-container button {
  padding: 0 8px;
}

.instructions ul {
  padding: 0;
  margin: 0;
}

.instructions li {
  list-style: none;
}
