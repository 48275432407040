.recipe-summary {
  max-width: 44rem;
  min-width: 20rem;
}

.recipe-summary ul {
  list-style: none;
  padding: 0;
}

.delete-recipe {
  position: relative;
  text-align: end;

  color: var(--kurr-black45);
  cursor: pointer;
  font-size: 11px;
  text-decoration: underline;
  margin: 0;
}
