.authentication__header {
  text-align: center;
  margin-bottom: 85px;

  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;

  text-align: center;

  color: #000000;
}

.authentication {
  max-width: 712px;
}
/* old
.authentication {
  width: 90%;
  max-width: 89rem;
  margin: 7rem auto;
  text-align: center;
}
*/

.authentication form {
  margin-bottom: 1rem;
}
