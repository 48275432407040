.dropdown-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 24px 0;
  align-items: center;
}

.dropdown-container p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: var(--kurr-black45);

  margin: 0;

  flex: 2;
}

.dropdown-container select {
  flex: 1;
  height: 32px;
  font-size: 14px;
  margin: 0px;
}

@media screen and (max-width: 640px) {
  .dropdown-container {
    margin: 16px 0;
  }

  .dropdown-container p {
    font-size: 13px;
    line-height: 19px;
  }
}
