.description {
  max-width: 44rem;
  min-width: 20rem;
}

.description label {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.25);
}

form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.file-upload img {
  width: 56px;
  height: 100px;
  border-radius: 4px;
  margin-right: 1rem;

  object-fit: cover;
}

.file-upload video {
  width: 56px;
  height: 100px;
  border-radius: 4px;
  margin-right: 1rem;

  object-fit: cover;
}

.delete-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
