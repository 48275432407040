.progress-bar {
  display: flex;
  width: 100%;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}
.progress-bar__ball {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;
  min-width: 34px;

  border-radius: 50%;

  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.ball--inactive {
  font-family: var(--font-family);
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.25);
}
.ball--active {
  font-family: var(--font-family);
  background-color: #feb79b;
  color: #ffffff;
  cursor: pointer;
}

.progress-bar__line {
  height: 3px;
  width: 39px;

  flex-grow: 1;
  margin: 0 1rem;

  border-radius: 4px;
}
.line--inactive {
  background-color: rgba(0, 0, 0, 0.03);
}
.line--active {
  background-color: #feb79b;
}
.line--inprogress {
  height: 3px;
  width: 50%;
  /*
  width: 19px;
  */

  background-color: #feb79b;
}

.font {
  font-family: var(--font-family);
}