.recipe-upload-header {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.recipe-upload-header__navigation {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.recipe-upload-header__navigation--spacing-container-left-and-right {
  flex: 1;
}

.recipe-upload-header__navigation--spacing-container-center {
  flex: 6;
}

.recipe-upload-header__navigation--back-button {
  visibility: hidden;
}

.recipe-upload-header__navigation--back-arrow {
  visibility: hidden;
}

.recipe-upload-header__navigation--title {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 50px;

  text-align: center;

  color: #000000;
}

@media screen and (max-width: 640px) {
  .recipe-upload-header__navigation {
    margin-top: 1rem;
  }
  .recipe-upload-header__navigation--title {
    font-size: 17px;
    line-height: 25px;
  }

  .recipe-upload-header__navigation--back-button {
    visibility: unset;
  }

  .recipe-upload-header__navigation--back-arrow {
    visibility: unset;
  }
}
