.home-container {
  width: 100vw;
  /* Set min-height to 100vh minus the height of header and footer */
  min-height: calc(100vh - (64px + 60px));

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

@media screen and (max-width: 1150px) {
  .home-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
