.hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;

  z-index: 0;
}

.hero__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  z-index: 2;
}

.hero__content h1 {
  font-size: clamp(1.75rem, 8.5vw, 3.5rem);
  font-family: var(--font-family);
  font-weight: 400;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.hero__overlay--full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.hero__overlay--top-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  z-index: 1;
}

.hero__overlay--bottom-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 33%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  z-index: 1;
}

.hero__content-button {
  width: 100%;
  padding: 0 16px;
  max-width: 375px;
}

.hero__app-store {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px;
  gap: 16px;
  margin: auto;
  width: 100%;
  /* bottom: 5vh; */
  bottom: 25%;

  z-index: 2;
}

.hero_app-store--heading {
  color: white;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: clamp(12px, 3vw, 17px);

  letter-spacing: 0.1em;
}

.hero__app-store__icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.hero_app-store__icons--size {
  width: 100%;
  height: clamp(28px, 6.5vw, 36px);
  object-fit: contain;
}

.hero__content-logo {
  width: 100%;
  height: auto;
  max-width: 375px;
  /* max-height: 100px; */
  object-fit: contain;
}

.hero__app-store__icons--modal {
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
}

/*iPhone 14 Pro Max*/
@media screen and (max-width: 430px) {
  .hero__content-logo {
    width: 100%;
    height: auto;
    max-width: 275px;
    /* max-height: 100px; */
    object-fit: contain;
  }
  .hero__app-store__icons {
    gap: 0px;
  }
}

/*iPhone SE*/
@media screen and (max-width: 320px) {
  .hero__content-logo {
    width: 100%;
    height: auto;
    max-width: 275px;
    /* max-height: 100px; */
    object-fit: contain;
    padding-left: 16px;
    padding-right: 16px;
  }
}
