.toggle {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.125);
    border-radius: 20px;
    
    transition: 0.2s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: var(--kurr-white);
    border-radius: 20px;
    transition: 0.2s;
}

input:checked + .slider {
    background-color: var(--piglet-peach2);
}

input:checked + .slider:before {
    transform: translateX(20px)
}

.toggle-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 24px 0;
}

.toggle-container p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: var(--kurr-black45);

    margin: 0;
}

@media screen and (max-width: 640px) {

    .toggle-container {
        margin: 16px 0;
    }

    .toggle-container p {
        font-size: 13px;
        line-height: 19px;
    }
}