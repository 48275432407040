.main-header {
  position: absolute;
  padding: 24px 32px;
  z-index: 1;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  
  top: 0;
  left: 0;

  z-index: 3;
}
.main-header img {
  padding: 0;
}

/*iPhone 14 Pro Max*/
@media screen and (max-width: 430px) {
  .main-header {
    padding: 16px;
  }
}

/*iPhone SE*/
@media screen and (max-width: 320px) {

}