.recipe-summary-item {
  font-family: var(--font-family);
  height: fit-content;
  min-height: 68px;
  word-wrap: break-word;
  width: 100%;
}

.recipe-summary-item__line {
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}

.recipe-summary-item__label {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.25);
}

.recipe-summary-item__value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.recipe-summary-item__value--missing {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.45);
}

.recipe-summary-item__recipe--incomplete {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.45);
}

.recipe-more-icon {
  color: var(--kurr-black25);
  cursor: pointer;
}

.recipe-more-icon-hide {
  /*color: rgba(0, 0, 0, 0.05);*/
  visibility: hidden;
  cursor: default;
}

.file-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.file-preview img {
  width: 56px;
  height: 100px;
  border-radius: 4px;
  margin-right: 1rem;

  object-fit: cover;
}

.file-preview video {
  width: 56px;
  height: 100px;
  border-radius: 4px;
  margin-right: 1rem;

  object-fit: cover;
}

.ingredients-string {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  font-family: var(--font-family);
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.45);
}

.ingredient-item {
  margin-right: 4px;
}

.recipe-summary-item__value li {
  margin-right: 16px;
  margin-bottom: 16px;
}
