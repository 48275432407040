.no-recipes {
  text-align: center;
  margin-top: 24px;

  font-family: var(--font-family);
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45);
}

.filters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.filters-container .toggle-container,
.filters-container .dropdown-container {
  margin: 0;
  padding: 0;
}

.creator-details {
  padding: 16px 8px;
  margin: 16px 0px 24 0px;
  border: 0.33px solid var(--kurr-black25);
  border-radius: 4px;
  background-color: var(--kurr-off-white);
  box-shadow: 2px 3px 9px rgba(0, 0, 0, 0.02);
  font-family: var(--font-family);
}

.creator-heading {
  font-size: 16px;
  font-weight: bold;
  color: "red";
  margin-bottom: 4px;
  padding-bottom: 16px;
}

.creator-list-item {
  font-size: 14px;
  color: var(--kurr-black45);
}
