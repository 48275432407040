.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /*width: 318px;*/
  width: 100%;
  height: 48px;
  cursor: pointer;

  background-color: var(--kurr-black);
  color: var(--kurr-white);
  border: none;

  box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  text-decoration: none;
  margin: 1rem 0;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
}

.button--center {
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;

  flex-direction: row;
}

.button:focus {
  outline: none;
}

.button--inverse {
  width: auto;
  height: auto;
  background: transparent;
  box-shadow: none;
  border-radius: 0;

  margin: 0;
  text-decoration-line: underline;
  color: var(--piglet-peach2);
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: rgba(0, 0, 0, 0.125);
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

/* Add Image */
.button__add-file {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border: none;
  border-radius: 50%;

  background: var(--piglet-peach2);
  color: white;

  cursor: pointer;
}

.button-bottom {
  margin-top: auto;
}

@media screen and (max-width: 640px) {
  .button {
    margin-bottom: 0;
  }

  .button--inverse {
    font-size: 13px;
  }
}
