.recipes-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.recipe-header {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-top: 48px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: var(--kurr-black85);
  margin: 0;
}

.recipes-summary-list-public {
  font-family: var(--font-family);
}

.recipes-summary-list-non-public {
  font-family: var(--font-family);
  color: var(--kurr-black45);
}

@media screen and (max-width: 640px) {
  .recipe-header {
    font-size: 14px;
  }
}
