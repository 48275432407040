* {
  font-family: var(--font-family);
  box-sizing: border-box;
}

.tags-input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
  color: var(--kurr-black25);
}

.tags-input {
  display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 50px;
	width: 100%;
	padding: 0 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;

}

.tags-input:focus-within {
  border: 1px solid var(--kurr-black45);
  box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.05);

}

.tags-input input::placeholder {
  color: var(--kurr-black25);
}


.tags-input input {
  flex: 1;
  border: none;
  height: 40px;
  padding: 8px 0 0 0;
  font-size: 18px;
  color: var(--kurr-black45);
}

.tags-input input:focus {
  outline: transparent;
  color: var(--kurr-black85);
}

.tags-input input:disabled {
  background-color: transparent;
}

.tags-input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.45);
  box-shadow: 4px 6px 18px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.85);
}

#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 4px;
	margin: 0 8px 8px 0;
	background: var(--piglet-peach2);
}

.tag .tag-close-icon {
  display: block;
  font-weight: 500;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: var(--piglet-peach2);
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  letter-spacing: 0;
}

.tags-input-subheading {
  text-align: right;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;

  color: rgba(0, 0, 0, 0.25);
}

.tags-input-subheading p {
  margin-top: 4px;
}

@media screen and (max-width: 640px) {



  .tags-input {
    min-height: 42px;
    font-size: 13px;
    line-height: 19px;
  }

  .tags-input input {
    flex: 1;
    border: none;
    height: 40px;
    padding: 6px 0 0 0;
    font-size: 13px;
    line-height: 19px;
  }

  .tag {
    height: 28px;
    padding: 0 8px;
    font-size: 11px;
  }


  .tag .tag-close-icon {
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 10px;
  }

  .tags-input-label {
    font-size: 12px;
    line-height: 18px;
}

}