.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 24px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  /*border: 3px solid #fff;*/
}

.menu-btn__burger {
  width: 30px;
  height: 2.5px;
  background: white;
  border-radius: 0px;
  /*box-shadow: 0 2px 5px rgba(255,101,47,.2);*/
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2.5px;
  background: white;
  border-radius: 0px;
  /*box-shadow: 0 2px 5px rgba(255,101,47,.2);*/
  transition: all .5s ease-in-out;
}
.menu-btn__burger::before {
  transform: translateY(-12px);
}
.menu-btn__burger::after {
  transform: translateY(12px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(50px);
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(-45deg) translate(-35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(45deg) translate(-35px, 35px);
}