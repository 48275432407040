.phone-container img {
  width: 739px;
  height: 696px;
  object-fit: scale-down;
}

@media screen and (max-width: 1400px) {
  .phone-container img {
    height: 500px;
  }
}

@media screen and (max-width: 1150px) {
  .phone-container img {
    width: 94vw;
    height: 940px;
    object-fit: cover;
    align-self: flex-start;
    object-position: 80% 0;
  }
}

@media screen and (max-width: 640px) {
  .phone-container img {
    object-position: 70% 0;
  }
}

@media screen and (max-width: 530px) {
  .phone-container img {
    height: 550px;
    object-fit: cover;
    object-position: 83% 0;
  }
}

@media screen and (max-width: 375px) {
  .phone-container img {
    object-position: 70% 0;
  }
}
