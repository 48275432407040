.card {
  display: flex;
  flex-direction: column;
  height: 100%;

  width: 100vw;
  box-sizing: border-box;
  padding: 1rem;
  overflow: hidden;

  max-width: 44rem;
  min-width: 20rem;
  /* Set min-height to 100vh minus the height of header and footer */
  min-height: calc(100vh - (96px + 60px));
  margin: auto;
  margin-top: 96px;
}

/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
  .card {
    margin-top: 64px;
  min-height: calc(100vh - (64px + 60px));

  }
}